<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="名称：">
					<el-input v-model.trim="formInline.title" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<div>
					<el-button type="primary" @click="modifyParkInfo(1)" icon="el-icon-plus">新增</el-button>
				</div>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="240"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(1, scope.row)" size="mini" :type="scope.row.enabled ? 'info' : 'warning'" :disabled="scope.row.enabled">
						修改
					</el-button>
					<el-button @click="modifyParkInfo(2, scope.row)" size="mini" type="danger"> 删除 </el-button>
				</template>
			</Table>
			<CreateDictionary ref="createDictionary" @getList="getList"></CreateDictionary>
		</div>
	</div>
</template>

<script>
export default {
	name: 'departmentList',
	components: {
		Table: () => import('@/components/Table/table'),
		CreateDictionary: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				title: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '名称',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '编号',
					prop: 'code',
					formatter: (row) => {
						return row.code || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
				},
			],
			otherList: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getOtherList();
	},
	methods: {
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		changeOpen(row) {
			let data = {
				enabled: row.enabled,
				id: row.id,
			};
			this.$http
				.put(this.api.getDictionaryItems + `/${row.id}.json`, data)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 获取字典列表拿到ID
		getOtherList() {
			let data = {
				current: 1,
				size: 99999,
			};
			this.$http
				.get(this.api.getDictionaries + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.otherList = res.data.collection || [];
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList() {
			this.loading = true;
			let data = {
				...this.formInline,
				...this.queryData,
				dictionaryCode: 'LESSON_CONTENT_TYPE',
			};
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(type, row) {
			if (type === 1) {
				let data = {
					row,
					id: this.otherList.find((item) => item.code === 'LESSON_CONTENT_TYPE')?.id || null,
				};
				this.$refs.createDictionary.init(data);
			} else {
				if (!row.id) return false;
				this.$confirm('此操作将删除数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						this.$http
							.delete(this.api.getDictionaryItems + `/${row.id}.json`)
							.then((res) => {
								if (res.data && res.data.success) {
									this.$message({
										type: 'success',
										message: '删除成功!',
									});
									this.getList();
								}
							})
							.catch((e) => {});
					})
					.catch(() => {});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.el-form {
	.el-form-item {
		margin-bottom: 16px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>
